@import './variables.scss';

form {
  margin-top: 1rem;
}

label {
  @extend %label;
  &.hasSubHeading {
    display: inline-block;
  }
  .lightText {
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 144%;
    color: $color-gray-dark;
    text-transform: none;
    letter-spacing: 0;
  }

  .inline {
    font-size: 1em;
    text-align: center;
  }

}

.subLabel {
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 144%;
  text-align: right;
  color: $color-gray-dark;
  float: right;
  margin-top: 2px;
}

input,
textarea,
select,
.mtl-picker-input.MuiFormControl-root,
.mtl-select-input.MuiInputBase-root {
  font-weight: 300;
  font-size: 1.4rem;
  color: $color-gray;
  background-color: $color-white;
  border-radius: $input-border-radius;
  border: 0;
  box-shadow: 0 2px 6px 0 rgba(33, 86, 168, 0.25);
  padding: 1.5rem 2rem;
  width: 100%;
  margin-bottom: 2.5rem;
  outline: none;
  scroll-margin-top: 3.8rem;
  scroll-margin-bottom: 3rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  &::placeholder {
    color: $color-gray-dark;
  }
  &.error {
    border-color: $color-red;
  }
}

.react-select {
  &__control {
    color: $color-gray;
    background-color: $color-white;
    border-radius: $input-border-radius;
    border: 0 !important;
    box-shadow: 0 2px 6px 0 rgba(33, 86, 168, 0.25) !important;
    padding: 0;
    max-width: 460px !important;

    &--menu-is-open {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  &__value-container,
  &__single-value,
  &__input {
    font-family: $font-body !important;
    color: $color-gray !important;

    input {
      margin-top: 9px;
      margin-bottom: 9px;
      box-shadow: none !important;
      width: 100% !important;
      color: $color-gray !important;
    }
  }
  &__indicator-separator {
    display: none !important;
  }
  &__indicator {
    svg {
      color: $color-green;
    }
  }
  &__menu {
    margin-top: 0 !important;
    border: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: 0 6px 6px 0 rgba(33, 86, 168, 0.25) !important;
    max-width: 460px !important;
  }
  &__option {
    color: #777d87 !important;
    padding: 8px;
    text-align: left;

    &:before {
      background-color: $color-gray;
      border-radius: 9px;
      content: ' ';
      display: inline-block;
      margin-right: 6px;
      height: 9px;
      width: 9px;
    }

    &:hover {
      background-color: #e6e6e6;
    }

    &--is-selected {
      background-color: #ffffff !important;
      font-weight: bold !important;

      &:before {
        background-color: $color-green;
      }
    }
  }
}

.mtl-select-input.MuiInputBase-root {
  padding: 0;

  & > div {
    font-size: 1.4rem;
    padding: 1.8rem 2.5rem;
    border: none;
  }

  &.MuiInput-underline:before {
    content: none;
    position: absolute;
    border-bottom: none;
  }

  &.MuiInput-underline:after {
    content: none;
    position: absolute;
    border-bottom: none;
  }

  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
}

.MuiMenu-paper {
  box-shadow: none;
  border: 1px solid #e6e6e6;

  .MuiListItemText-primary {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 300;
    font-size: 1.4rem;
  }

  .MuiCheckbox-colorSecondary {
    &.Mui-checked {
      color: $color-orange;
    }

    .MuiSvgIcon-root {
      width: 3rem;
      height: 3rem;
      font-size: 3rem;
    }
  }
}

.mtl-picker-input.MuiFormControl-root {
  padding: 0;
  & > div {
    & > input {
      font-size: 1.4rem;
      padding: 1.8rem 2.5rem;
      border: none;
    }
  }
  .MuiInput-underline:before {
    content: none;
    border-bottom: none;
  }
  .MuiInput-underline:after {
    content: none;
    border-bottom: none;
  }
  .MuiSvgIcon-root {
    font-size: 2.5rem;
  }
  .MuiButtonBase-root {
    padding: 0.5rem 0.5rem;
    margin-right: 0.5rem;
    border-width: 1px;
    border-radius: $input-border-radius;
    &:focus {
      box-shadow: none;
    }
  }
  .MuiTouchRipple-root {
    display: none;
  }
}

.input-feedback {
  margin-bottom: 25px;
  margin-top: -15px;
  font-size: 1.4rem;
  font-weight: 300;
  color: $color-gray-dark;
}
.checkboxWrap {
  margin-bottom: 37px;
  @include clearfix;
}
input[type='checkbox'] {
  display: block;
  float: left;
  width: 29px;
  height: 29px;
  margin: 0;
  appearance: none;
  padding: 0;
  border: 1px solid #c4c4c4;
  border-radius: $input-border-radius;
  outline: none;
  cursor: pointer;
  transition: background-image 0.4s ease-out;
  &:checked {
    background-color: $color-orange;
    border: 1px solid $color-orange;
  }
}
.switchWrapper {
  display: flex;
  label {
    margin-bottom: 0;
    line-height: 2.5rem;
    margin-right: 0.5rem;
  }
  input.inputSwitch {
    background: #ececfb;
    float: none;
    border-radius: 1.5rem;
    position: relative;
    width: 5.5rem;
    height: 2.5rem;
    padding: 0.3rem 0.8rem;
    border: none;
    text-align: right;
    margin-right: 0;

    &:after {
      content: '';
      background: red;
      width: 1.9rem;
      height: 1.9rem;
      border-radius: 50%;
      position: absolute;
      top: 0.3rem;
      left: 0.5rem;
      transition: all 0.15s linear;
    }
    &:before {
      content: 'Off';
      transition: all 0.5s ease;
      font-weight: 300;
      color: #828c9a;
      top: 0.1rem;
      position: relative;
    }
    &:checked {
      text-align: left;
      &:after {
        left: 3rem;
        background: $color-green;
      }
      &:before {
        content: 'On';
        text-align: left;
      }
    }
  }
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.radioGroupWrap {
  display: flex;
  .copy {
    margin-bottom: 0;
    margin-left: 2rem;
    margin-top: 0.8rem;
    .lightText {
      margin-top: 0.3rem;
    }
  }
}
.radioGroup {
  div {
    margin: 0;
  }
}

select {
  appearance: none;
  color: #171d33;
  border-radius: 5px;
  border: 1px solid $color-blue-light;
  padding: 1.8rem 2.5rem;
  width: 100%;
  background-color: #fff;
  font-weight: 300;
  font-size: 1.4rem;
  margin-bottom: 2.5rem;
  background-image: url('~assets/icons/icon-arrow-down-gray.svg');
  background-repeat: no-repeat;
  background-position: center right 2rem;
  option:first-child {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.37;
    letter-spacing: 0.2px;
    color: $color-gray-dark;
  }
}

.default {
  color: $color-gray-dark;
}

.input-feedback {
  color: $color-red;
}

label span.required-field {
  color: $color-green;
}

.MuiAlert-root {
  margin-bottom: 2rem;

  .MuiAlert-message {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@import '~scss/variables.scss';

.redirectURIs {
  height: 17rem;
}

.radioFieldSet {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
}

.radioFieldSetLabel {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;
  font-size: 12px;
}

.policyOptions {
  min-height: 200px;

  .policyOptionRadios {
    display: block !important;
    margin-left: auto;
    margin-right: auto;

    .policyOptionButton {
      text-align: center;
      background-color: $color-gray-lightest;
      border: 1px solid $color-secondary;
      box-shadow: none;
      border-radius: 10px;

      &:hover {
        background-color: darken($color: $color-gray-lightest, $amount: 5);
      }

      &.selected {
        border-color: $color-green-light;
      }

      strong {
        display: block;
      }
    }
  }

  .policyUploadRegion {
    text-align: center;
    margin-top: 2rem;

    input[name='policy-upload'] {
      max-width: 325px;
    }
  }
}

.checklistItems {
  margin-left: 0;
  padding-left: 0;
  padding-right: 5rem;
  margin-top: 0;
  font-size: 1.25rem;
}

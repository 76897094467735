@import '~scss/variables.scss';

.callout {
  border-left: solid 10px $color-green-light;
  box-shadow: 0 2px 6px 0 rgba(33, 86, 168, 0.11) !important;
  border-radius: 2px !important;
  max-width: 600px;

  .calloutTitle {
    padding-bottom: 1rem !important;
    span {
      color: $color-gray;
      font-weight: bold !important;
      font-family: $font-heading !important;
    }
  }
  .calloutContent {
    font-family: $font-body !important;
    color: $color-gray;
    font-size: 1.25rem;
  }
}

html {
	box-sizing: border-box;
	font-size: 62.5%;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
ul.reset {
	list-style: none;
	padding-left: 0;
	margin: 0;
}

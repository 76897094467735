#patient-detail table, #patient-list table {
    // width: 100%;
    table-layout: fixed;
}

#patient-detail th, #patient-detail td {
    vertical-align: top;
    /* text-align: justify; */
    width: 20%;
    overflow: auto;
}
#patient-detail th:last-child, #patient-detail td:last-child {
    width: 40%;
} 

table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
}

.button__container {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
@import '~scss/variables.scss';

.redirectURIs {
  height: 17rem;
}

.policyOptions {
  min-height: 200px;

  .policyOptionRadios {
    display: block !important;
    margin-left: auto;
    margin-right: auto;

    .policyOptionButton {
      text-align: center;
      background-color: $color-gray-lightest;
      border: 1px solid $color-secondary;
      box-shadow: none;
      border-radius: 10px;

      &:hover {
        background-color: darken($color: $color-gray-lightest, $amount: 5);
      }

      &.selected {
        border-color: $color-green-light;
      }

      strong {
        display: block;
      }
    }
  }

  .policyUploadRegion {
    text-align: center;
    margin-top: 2rem;

    .fileMetadata {
      color: $color-gray;
      background-color: $color-white;
      border-radius: $input-border-radius;
      border: 0 !important;
      box-shadow: 0 2px 6px 0 rgba(33, 86, 168, 0.25) !important;
      padding: 1.5rem 2rem;
      max-width: 400px;
      margin-right: auto;
      margin-left: auto;

      button {
        float: right;
      }
    }
    input[name='policy-upload'] {
      max-width: 325px;
    }
  }
}

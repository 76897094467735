@import '~scss/variables.scss';

.detailActions {
  text-align: right;
  margin-bottom: 1rem;

  .detailsEditLink {
    color: $color-gray;
  }

  button {
    font-size: 1rem !important;
    color: $color-primary;

    &.cancel {
      color: $color-gray;
    }
  }
}

.actionHeader {
  padding-bottom: 1rem;

  .deleteButton {
    color: $color-red;
  }

  .deleteActions {
    margin-top: 1rem;
  }
}

.topBar {
  .detailActions {
    text-align: right;
    margin-bottom: 1rem;
  }

  .appImageRegion {
    text-align: right;
    padding-right: 30px;
  }
  .appImage {
    color: $color-gray;
    width: 100px;
    height: 8rem;
    margin-top: -13px;
    margin-right: 1rem;
  }
  .nameInput {
    font-size: 2.5rem;
    border: 2px solid $color-blue-light;
    padding: 1rem 2rem;
  }
  .lastUpdated {
    color: $color-gray;
    font-size: 1.25rem;
  }
}

.mainDetailsContainer {
  margin-top: 2rem;

  &.topDetails {
    .detailCard {
      min-height: 225px;
    }
  }
  &.bottomDetails {
    .detailCard {
      min-height: 375px;

      .detailActions {
        float: right;
      }

      .infoMessage {
        font-size: 1.25rem;
        color: $color-gray;
        margin-bottom: 2rem;
      }

      label {
        margin-top: 1rem;
        margin-bottom: 0;
        font-size: 1.25rem;
        color: $color-gray;
        font-weight: normal;
      }
      .value {
        margin-top: 0;
        color: $color-gray;
        font-family: $font-code;
        font-size: 1.25rem;
      }
    }
  }

  .detailRegion {
    + .detailRegion {
      padding-left: 2rem;
    }

    .detailCard {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;

      &.focused {
        border: 2px solid $color-blue-light;
      }
      &.success {
        border: 1px solid $color-green-light;
      }

      h3 {
        margin-top: 0;
        font-size: 1.75rem;
      }
      .warningMessage {
        font-size: 1.25rem;
        color: $color-gray;

        svg {
          color: $color-red;
          vertical-align: text-bottom;
        }

        + .warningMessage,
        + .successMessage {
          margin-top: 1rem;
        }
      }
      .successMessage {
        font-size: 1.25rem;
        color: $color-gray;

        svg {
          color: $color-green;
          vertical-align: text-bottom;
        }

        div {
          margin-bottom: 1rem;

          span {
            font-size: 1.1rem;
            color: $color-blue-lightest;
          }
        }
      }
      input,
      textarea {
        box-shadow: none;
        border: 1px solid $color-gray-lighter;
        padding: 1rem;
        font-size: 1.1rem;
        margin-bottom: 0;
      }
      .miniSelect {
        font-size: 1.1rem !important;

        > div:first-of-type {
          max-width: none !important;
          box-shadow: none !important;
          border: 1px solid $color-gray-lighter !important;
        }
      }

      .detailBody {
        flex: 1 0 auto;
        padding-bottom: 0;
      }

      .detailActions {
        padding: 0 16px 0 16px;

        &.policyAndSecurityActions {
          display: flex;
          // margin-top: 16px;

          .uploadButton {
            cursor: pointer;
            background-color: $color-gray-lightest;
            color: $color-gray;
            border: 1px solid $color-muted;
            padding: 5px;
            min-width: inherit;
            font-size: 1rem;
            box-shadow: none;
            margin-bottom: 0;

            &:hover:not(:disabled) {
              background: darken($color-gray-lightest, 3);
              transition: 0.1s;
            }

            svg {
              display: inline-block;
              vertical-align: sub;
              margin-right: 2px;
              color: $color-blue-light;
            }
          }

          button {
            color: $color-gray;

            &.removeButton {
              color: $color-red;

              svg {
                color: $color-red;
              }
            }

            svg {
              color: $color-blue-light;
            }
          }
        }
      }

      &.appCredentialsCard {
        color: $color-white;
        background-image: linear-gradient(112deg, $color-primary -61%, $color-blue-dark 100%);

        h3 {
          color: $color-white;
        }

        .credShowHideButton {
          float: right;
          color: $color-white;
          background-color: $color-blue;
          border-color: $color-blue-light;

          svg {
            color: $color-blue-light;
          }
        }

        .credLabel,
        .credValue {
          font-size: 1rem;
          padding: 6px 0;
        }
        .credValue {
          font-size: 1.25rem;
          padding: 6px 8px;
          background-color: rgba(25, 67, 132, 0.34);

          pre,
          input {
            margin: 0;
            display: inline-block;
          }

          input {
            width: 445px;
            border: none;
            box-shadow: none;
            padding: 0;
            background: transparent;
            color: $color-white;
            font-family: $font-code !important;
          }

          + .credLabel {
            margin-top: 1rem;
          }

          .copyButton {
            display: block;
            margin-top: 0;
            margin-left: 1.5rem;
            float: right;

            svg {
              vertical-align: text-bottom;
              cursor: pointer;
              color: $color-primary;
            }
          }
        }

        &.forProd {
          background-image: linear-gradient(112deg, #d1df00 -61%, #21a884);

          .credShowHideButton {
            background-color: $color-green-darkest;

            svg {
              color: $color-primary;
            }
          }

          .credValue {
            background-color: $color-green-darkest;
          }
        }
      }
    }
  }
}

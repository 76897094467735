@import '~scss/variables.scss';
@import '~scss/mixins.scss';

.button {
  @include button;

  &:hover {
    text-decoration: none;
  }

  .loadingSpinner {
    height: 2rem;
    width: 2rem;
  }

  .loadingSpinnerContainer {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
  }

  &.copy:before {
    content: attr(data-content);
    position: absolute;
    top: 0;
    opacity: 0;
    animation-duration: 0.3s;
    animation-name: copy;
  }
}

@keyframes copy {
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: -1.5rem;
    opacity: 0;
  }
}

.notice {
  background-color: $color-red;
  color: $color-white;
  transition: 0.1s;
  &:hover:not(:disabled) {
    background-color: darken($color-red, 8);
    transition: 0.1s;
  }
}

.secondary {
  color: $color-gray-darker;
  background-color: $color-gray-lighter;
  transition: 0.1s;
  &:hover:not(:disabled) {
    background: darken($color-gray-lighter, 3);
    transition: 0.1s;
  }
}
.tertiary {
  background-color: $color-gray-lightest;
  color: $color-gray;
  border: 1px solid $color-muted;
  padding: 5px;
  min-width: inherit;
  font-size: 1rem;
  box-shadow: none;

  &:hover:not(:disabled) {
    background: darken($color-gray-lightest, 3);
    transition: 0.1s;
  }

  svg {
    display: inline-block;
    vertical-align: sub;
    margin-right: 2px;
  }
}

.destructive {
  background-color: $color-red;
  color: #fff;
  border: 0.1rem solid $color-red-border;
  &:hover:not(:disabled) {
    background-color: darken($color-red, 3);
  }
}

.dropdown {
  @extend .secondary;
  padding-right: 6rem;
  position: relative;
  text-align: left;
  &:not(.isLoading) {
    &:after {
      content: '';
      position: absolute;
      top: -0.1rem;
      right: -0.1rem;
      bottom: -0.1rem;
      width: 4.5rem;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-left: 0.1rem solid $color-gray;
      background-image: url('~assets/icons/icon-arrow-down-gray.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
    &.active {
      &:after {
        background-color: $color-blue-dark;
        background-image: url('~assets/icons/icon-arrow-down-white.svg');
        border-left: 0;
      }
    }
  }
}

.link {
  background-color: transparent;
  padding: 0;
  border: 0;
  color: $color-primary;
  min-width: auto;
  font-weight: $font-weight-regular;
  font-size: 1.4rem;
  box-shadow: none;
  &:hover {
    background-color: transparent;
    text-decoration: underline;
    &:not(:disabled) {
      background-color: transparent;
    }
  }
}

.buttonLink {
  font-size: 1.6rem;
}

@import '~scss/variables.scss';

button,
.btn {
  transition: 0.1s;
  background: transparent;
  font-family: $font-body;
  border: none;
  color: #fff;
  &:not(:disabled) {
    cursor: pointer;
  }
  text-decoration: none;
  font-size: 1.6rem;
  &:disabled {
    opacity: 0.5;
  }

  &.buttonIcon {
    min-width: 4.4rem;
    min-height: 4.4rem;
    padding: 0;
    background-color: transparent;
  }

  &.buttonListItem {
    display: block;
    width: 100%;
    background-color: transparent;
    font-size: 1.2rem;
    text-align: left;
    padding: 1rem 2rem;
    font-weight: 600;
    border-radius: 0;
    &Warning {
      color: $color-red;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
  + button {
    margin-left: 2rem;
  }
}

.buttonLink {
  font-size: inherit;
  line-height: inherit;
  background-color: transparent;
  padding: 0;
  @include tablet-landscape-up {
    min-width: auto;
  }
}

a.button {
  @include button;
}

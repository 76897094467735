@import '~scss/variables.scss';

.tile {
  float: left;
  margin: 0 34px 21px 0;

  &.standalone {
    margin: 0 !important;
    width: 100%;

    .tileCard {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .tileCard {
    background-image: linear-gradient(132deg, $color-primary -64%, $color-blue-dark 100%);
    box-shadow: 0 2px 6px 0 rgba(33, 86, 168, 0.25) !important;
    width: 242px;
    min-height: 160px;
    display: flex;
    flex-direction: column;

    .tileContent {
      cursor: pointer;
      text-align: center;

      svg {
        position: relative;
        top: 10px;
        font-size: 5rem;
        color: $color-white;
      }

      .plusIcon {
        margin-top: 1rem;
        width: 70px;
        height: 70px;
        box-shadow: 0 5px 10px 0 rgba(25, 67, 132, 0.62);
        border: solid 1px $color-white;
        border-radius: 35px;
        background-color: rgba(25, 67, 132, 0.35);
        display: inline-block;
      }

      .label {
        padding-top: 1.75rem;
        color: $color-white;
        font-size: 1.5rem;
        font-family: $font-body;
        font-weight: bold;
      }
    }
  }
}

@import '~scss/mixins';
// Typography
html {
  font-size: 62.5%;
}
body {
  color: $color-blue-dark;
  font-family: $font-body;
  line-height: 1.42;
  overflow-x: hidden;
  font-size: 1.4rem;
  font-weight: $font-weight-light;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-heading;
  font-weight: 500;
  color: $color-gray-dark;

  &.section-title {
    margin-top: 0;
  }
}
h1,
.h1 {
  font-size: 3rem;
  font-weight: $font-weight-bold;
  line-height: 1.1;
  margin: 0 0 0.5rem 0;
  @include tablet-landscape-up {
    font-size: 4rem;
  }
}
h2,
.h2 {
  font-size: 2.2rem;
  font-weight: $font-weight-bold;
  @include tablet-landscape-up {
    font-size: 2.8rem;
  }
}
h3,
.h3 {
  font-size: 2rem;
  font-weight: $font-weight-medium;
}
h4,
.h4 {
  font-size: 1.8rem;
  font-weight: $font-weight-medium;
}
h5,
.h5 {
  font-size: 1.6rem;
  font-weight: $font-weight-medium;
}
h6,
.h6 {
  font-size: 1.4rem;
  font-weight: $font-weight-medium;
}

p {
  margin-top: 0;
}

a {
  color: $color-primary;
  cursor: pointer;
  text-decoration: none;
  font-weight: $font-weight-regular;
  &:hover {
    text-decoration: underline;
  }
}

div,
p {
  &.subtitle {
    color: $color-gray;
    margin: 2rem 0;
  }
}

.copy {
  margin-bottom: 4rem;
}

.copyExtraSmall {
  font-weight: $font-weight-light;
  @include font-size-xs;
}

.textSmall {
  font-size: 0.8rem;
  line-height: 1rem;
  vertical-align: top;
}

.lightText {
  font-weight: $font-weight-light;
  color: $color-gray-dark;
}

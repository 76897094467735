@import '~scss/variables.scss';

.logo {
  width: 155px;
  margin-left: 3rem;

  &:hover {
    cursor: pointer;
  }
}
.buttonWrapper {
  float: right;
  border-left: 1px $color-gray-lightest solid;
  margin-top: -25px !important;
  height: 66px;

  .adminChip {
    margin-right: 1rem;
    background-color: $color-success !important;

    span {
      color: $color-gray !important;
    }
  }
}
.avatarButton {
  height: 66px;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  border-radius: 0 !important;

  &:hover {
    background-color: $color-gray-lightest !important;
  }
  &.openMenu {
    background-color: $color-blue !important;

    svg {
      color: $color-gray-lightest !important;
    }
  }

  svg {
    margin-left: 1rem;
    font-size: 2rem;
  }
}
.avatar {
  font-size: 1rem !important;
  width: 30px !important;
  height: 30px !important;
  background-image: linear-gradient(146deg, $color-blue-dark 0%, darken($color-blue-dark, 20%) 100%);
  box-shadow: 0 2px 3px 0 rgba(33, 86, 168, 0.36), inset 0 1px 1px 0 rgba(255, 255, 255, 0.3);
}

.dropdownMenu {
  border-radius: 0 !important;
  background-color: $color-blue !important;
  margin-left: 2rem !important;
  border: 0 !important;

  ul {
    min-width: 190px;

    li {
      color: $color-white !important;
      font-size: 1.25rem;
      padding-top: 1rem;
      padding-bottom: 1rem;

      svg {
        margin-right: 1rem;
        color: $color-blue-light !important;
      }
    }
  }
}

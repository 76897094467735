@import '~scss/variables';

.searchGroup {
  padding-right: 1rem;
}

.orgDetails {
  min-height: 165px;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;

  h5,
  .detailInfo {
    color: $color-gray;
    font-size: 1.25rem;
  }
  h5 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: bolder;
  }

  .editLink {
    color: $color-blue-light;
  }
}

.tile {
  display: inline-block !important;
}

.inviteSentInfo {
  margin-top: 3rem;

  small {
    color: $color-blue-light;
  }

  .sentAt {
    color: $color-gray;
    font-size: 1.25rem;
    line-height: 27px;
  }
}

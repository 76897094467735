@import '~scss/variables';
@import '~scss/mixins';

.tabBar {
  @include clearfix;
  border-bottom: 1px solid $color-muted;
  padding-left: 0;
  margin-bottom: 3rem;
  display: flex;
  flex-grow: 1;
  li {
    float: left;
    display: block;
    margin-right: 28px;
    margin-bottom: -1px;
    button {
      text-decoration: none;
      letter-spacing: 0.1rem;
      color: $color-gray;
      padding: 0.8rem 0;
      font-size: 1.5rem;
      display: inline-block;
      &:hover {
        color: darken($color-gray, 12);
        transition: 0s;
      }
      &.active {
        font-weight: bold;
        border-bottom: solid 3px $color-green;
        transition: 0s;
      }
    }
  }
}

@import '~scss/variables.scss';

.callout {
  box-shadow: 0 2px 6px 0 rgba(33, 86, 168, 0.11);
  border-radius: 2px !important;

  &.hideBorder {
    border: none;
    box-shadow: none;
  }

  .calloutHeader {
    padding-bottom: 0 !important;
    svg {
      cursor: pointer;
      color: $color-blue-light;
    }
  }
  .calloutContent {
    padding-top: 0 !important;
    font-family: $font-body !important;
    color: $color-gray-dark;
    font-size: 1.1rem;

    pre {
      margin: 0;
      font-family: $font-code !important;
    }
  }
}

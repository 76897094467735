@import '~scss/variables';

h5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.orgList {
  margin-top: 0;
  padding-left: 0;

  li {
    display: block;
    margin-bottom: 1px;

    button {
      color: $color-gray-dark;
      background-color: $color-secondary;
      padding: 1.5rem 1.25rem;
      font-size: 1.25rem;
      display: block;
      width: 100%;
      text-align: left;

      &.active {
        color: $color-white !important;
        background-color: $color-primary;
      }

      &:hover:not(.active) {
        background-color: darken($color-secondary, 10);
        transition: 0.1s;
      }

      svg {
        float: right;
      }
    }
  }
}

.orgDetails {
  min-height: 165px;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;
  margin-left: 4rem;

  h4 {
    margin-top: 0;
  }

  h5,
  .detailInfo {
    color: $color-gray;
    font-size: 1.25rem;
  }
  h5 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: bolder;
  }
  .detailInfo {
    margin-bottom: 3rem;
  }

  .editLink {
    color: $color-blue-light;
  }

  hr {
    margin-bottom: 3rem;
  }

  .userInfo {
    h3 {
      font-size: 3rem;
      line-height: 3rem;
      color: $color-blue-light;
      margin: 0;
    }
  }
}

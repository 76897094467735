@import '~scss/variables';

html {
  font-size: 62.5%;
}

body {
  background-color: $color-gray-lightest;
}

//Color outline when tabbing
*:focus:not(h1):not(h2):not([type='text']):not(textarea):not([type='password']) {
  box-shadow: 0 0 0 0.3rem #{$color-orange} !important;
  outline: none;
}

body:not(.user-is-tabbing) *:focus:not(h1):not(h2):not([type='text']):not(textarea):not([type='password']) {
  box-shadow: none !important;
  outline: none;
}

.screenreader-text {
  position: absolute;
  left: -999px;
  width: 1px;
  height: 1px;
}

hr {
  height: 2px;
  background-color: $color-muted;
  border: 0;
}

.alignTextRight {
  text-align: right;
}
.alignTextCenter {
  text-align: center;
}

@import '~scss/variables.scss';

.attestationListItem {
  margin-bottom: 2rem;

  h4,
  .description {
    margin: 0 1rem 0.5rem;
  }
  a,
  .description {
    font-size: 1.25rem;
  }
  .description {
    color: $color-gray;
  }
  a {
    display: block;
    // margin: 0 1rem 0.5rem;
  }
}

.attestDetails {
  margin: 0 1rem 0.5rem;
  font-size: 1.2rem;
  color: $color-gray;
  padding-left: 1rem;

  .detailLabel {
    color: $color-blue-lightest;
    margin: 1rem 0;

    &.approved {
      color: $color-green;
    }
    &.rejected {
      color: $color-red;
    }
  }
}

hr {
  margin-top: 3rem;
}

.attestRow {
  margin: 1rem 0 !important;
  font-size: 1.2rem;
  color: $color-gray;

  svg {
    font-size: 2rem;
  }
}
.denyMessage {
  margin: 2rem 5rem;
  font-size: 1.2rem;
  color: $color-gray;
}

.loaderPanel {
  padding-top: 5rem;
  text-align: center;
  font-size: 2rem;
  color: $color-gray;

  div + span {
    vertical-align: top;
  }
}
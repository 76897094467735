@import '~scss/variables.scss';

.chip {
  border-radius: 4px !important;
  min-width: 75px;
  font-family: $font-body !important;
  font-size: 1rem !important;
  font-weight: bold;

  background-color: $color-muted !important;
  color: $color-gray !important;

  svg {
    color: $color-gray;
  }

  + .chip {
    margin-left: 0.5rem;
  }

  &.draft {
    // background-color: $color-muted !important;
  }
  &.pending {
    background-color: $color-warning !important;
  }
  &.approved {
    background-color: $color-success !important;
  }
  &.issue {
    background-color: $color-danger !important;
  }
  &.admin {
    color: $color-blue-light !important;
  }
  &.payer {
    color: $color-gray-dark !important;
  }
  &.developer {
    color: $color-primary !important;
  }
  &.dev {
    color: $color-blue !important;
  }
  &.prod {
    color: $color-primary !important;
  }
}

.sectionPadding {
	padding: 4rem 0;
}

.container {
	background: #f6f6f6;
	@include tablet-landscape-up {
		display: flex;
		height: 100vh;
		flex-direction: column;
	}
}

@import '~scss/variables.scss';

.inputGroup {
  width: 100%;
  display: flex;

  .labelPart {
    label {
      color: $color-gray;
      font-size: 1.25rem;
      font-weight: normal;
      padding: 1.5rem 1rem 1.5rem 0;
    }
  }
  .inputPart {
    flex: 1;
  }
}

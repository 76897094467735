@import '~scss/variables.scss';

.menuList {
  padding: 0 !important;
  z-index: 99;
}

.menuItem {
  background-color: $color-blue-darkest !important;
  margin-bottom: 1px !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;

  &.selected {
    background-color: darken($color-blue-darkest, 10%) !important;
  }

  span {
    color: $color-white !important;
    font-size: 1.25rem !important;
  }
  svg {
    color: $color-blue-light !important;
  }
}

.subMenu {
  background-color: darken($color-blue-darkest, 10%);

  span {
    color: $color-white !important;
    font-size: 1.1rem !important;
  }

  svg {
    margin-right: 1rem;
    color: $color-green;
    vertical-align: middle;
  }

  .active {
    span {
      font-weight: bold;
    }
  }
}

.trademarkAndTermsRegion {
  position: absolute;
  bottom: 0;
  width: $portal-sidebar-width;
  z-index: 0;

  .trademarkMsg {
    color: $color-white;
    padding: 1.5rem;
    font-size: 1rem;
  }
  .terms {
    background-color: $color-blue-darkest;
    padding: 1rem 1.5rem;

    button {
      font-size: 1.25rem;
      text-decoration: underline;
    }
  }
}

@import '~scss/variables.scss';
@import '~scss/mixins.scss';

.appAuth,
.appDevPortal,
.appPayerPortal,
.appAdminPortal {
  min-height: 100vh;
  font-weight: $font-weight-regular;
  background-color: $color-gray-lightest;
}

.appDevPortal,
.appPayerPortal,
.appAdminPortal {
  .logoInternal {
    display: none;
  }

  .header {
    background-color: $color-white;
    padding: 2.5rem 0;
    height: $portal-header-height;
    position: fixed;
    z-index: 99;
  }

  .mainContainer {
    padding-top: $portal-header-height;
    display: flex;
    align-items: stretch;

    .secondaryContent {
      box-sizing: border-box;
      width: $portal-sidebar-width;
      height: calc(100vh - #{$portal-header-height});
      background-color: $color-blue;
      position: fixed;
      overflow-x: hidden;
      min-height: 450px;
      z-index: 99;

      &:hover {
        overflow-y: auto;
      }
    }
    .primaryContent {
      padding: 3rem;
      margin-left: $portal-sidebar-width;
      flex: 1;

      .contentSection {
        max-width: 1070px;
      }
    }
  }
}

.appAuth {
  background-image: linear-gradient(146deg, $color-primary 0%, $color-blue-dark 100%);
  background-size: 150%;
  display: flex;
  align-items: stretch;
  flex-direction: row-reverse;
  :global {
    h2,
    .h2 {
      margin-bottom: 1.6rem;
    }
  }

  .header {
    display: none;
  }

  .mainContainer {
    width: 100%;

    .logoInternal {
      display: block;
    }

    .signUpSignInArea {
      color: $color-gray;
      text-align: right;

      &.signUpArea {
        margin-top: 2rem;
        text-align: left;
      }

      button {
        display: inline-block;
        margin-left: 1rem;
        margin-top: 2rem;
      }
    }
    .forgotPasswordLink,
    .signUpSignInLink {
      font-size: 1.4rem;
      display: block;
      text-decoration: none;
      margin-top: 3rem;
    }

    &.leftRight,
    &.centerFrame {
      .secondaryContent {
        background-image: url('~assets/backgrounds/backgroundShapes.svg');
        mix-blend-mode: multiply;
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100vh;
        flex: 1;
        background-attachment: fixed;
        z-index: 0;
      }
      .primaryContent {
        background-color: $color-gray-lightest;
        z-index: 99;
        min-width: 750px;
      }
    }

    &.fullFrame {
      margin-top: 6rem;

      .secondaryContent {
        display: none;
      }
      .primaryContent {
        background-color: $color-gray-lightest;
        padding: 8rem;
        width: 100%;
        .contentSection {
          width: 40%;
        }
      }
    }
    &.leftRight {
      .primaryContent {
        padding: 20rem 15rem 0;
        width: 60%;

        .contentSection {
          width: 60%;
        }
      }
    }
    &.centerFrame {
      .primaryContent {
        padding: 8rem;
        padding-right: 4rem;
        margin-top: 5rem;
        margin-bottom: 10rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 932px;

        &.registrationFrame,
        &.completeSignUpFrame {
          padding-right: 8rem;
          max-width: 750px;
        }

        @media only screen and (max-width: $mobile-width) {
          min-width: $mobile-width !important;
          padding: 3rem;
        }
      }
    }
  }
}

.modalHeader {
  margin-top: 0;
}
.modalActionButtons {
  padding-top: 4rem;
}
.subtitle {
  color: $color-gray;
  margin: 2rem 0;
}
.error {
  color: $color-red;
}
.success {
  color: $color-green;
}

.detailsRegion {
  margin-bottom: 2rem;
  min-width: 700px;
}
.detailLabel {
  text-transform: uppercase;
  color: $color-blue-lightest;
  font-size: 1rem;
  margin-bottom: 0.75rem;
}
.detailData {
  color: $color-gray;
  font-size: 1.2rem;
  margin-top: 0.75rem;

  + .detailData {
    margin-top: 0;
  }

  button {
    margin-top: 0.75rem;
  }
}

.sendForgotPasswordReset {
  width: 100%;
}
.accountSettingsForgotPassword {
  float: right;
}

.formRow {
  [class*='react-select__control'] {
    margin-bottom: 2.5rem !important;
    max-width: 100% !important;
  }
  [class*='react-select__menu'] {
    max-width: 100% !important;
  }
}
.formTwoCols {
  .formRow {
    + .formRow {
      padding-left: 1rem;
    }
  }
}

.passwordInput {
  margin-bottom: 0;
}
.passwordRequirementsText {
  margin-bottom: 2rem !important;
  font-size: 1.1rem !important;
}

li {
  font-size: 1.25rem;
  color: $color-gray;
}

.docDivider {
  text-align: left;
  width: 90px;
  background-color: $color-green;
}

.compareTable {
  font-size: 1.25rem;
  color: $color-gray;
  margin: 5rem 0;
  border-spacing: unset;
  background-color: $color-white;

  caption {
    font-style: italic;
  }

  th,
  td {
    border: 1px solid $color-gray;
    padding: 1rem 2rem;

    + th,
    + td {
      border-left: none;
    }
  }

  tr {
    + tr {
      th,
      td {
        border-top: none;
      }
    }
  }
}

.payerSection {
  padding-left: 40px;
  text-align: center;

  .question {
    margin-top: 9rem;
    margin-bottom: 2.8rem;
    background-color: rgba($color: $color-blue-lightest, $alpha: 0.5);
    padding: 3rem;
    text-align: center;

    .questionContent {
      margin-top: 2.7rem;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.38;
      color: $color-blue;
    }
  }

  .instruction {
    text-align: justify;
  }

  .emailButton {
    margin-top: 2rem;
  }
}

@import '~scss/variables.scss';

.banner {
  border-radius: 4px;
  padding: 3rem;
  background-image: linear-gradient(250deg, $color-gray-lightest 0%, $color-cyan-light 100%);
  color: $color-gray-dark;

  :first-child {
    margin-top: 0;
  }
}

.userIcon {
  font-size: 8rem !important;
}

.formRow {
  div + div {
    padding-left: 2rem;
  }
}
.saveRow {
  padding: 3rem 1.5rem;
}

.extraFunctionCard {
  margin-bottom: 2rem;

  h3 {
    font-size: 1.75rem;
  }
}

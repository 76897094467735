@import '~scss/variables';

.list {
  padding-left: 0;
  list-style-type: none;
  display: flex;
  margin: 0.6rem 0 1rem 0;
}

.listItem {
  margin-right: 2.5rem;
  position: relative;
  font-size: 1.2rem;

  a {
    color: $color-gray !important;
  }

  &:after {
    content: '';
    display: block;
    height: 1rem;
    width: 1rem;
    background-image: url('~assets/icons/icon-arrow-right.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: -1.5rem;
    top: 50%;
    transform: translateX(50%) translateX(-0.2rem) translateY(-50%) translateY(-0.1rem);
  }
  &:last-child {
    margin-right: 0;
    color: $color-gray-light !important;
    &:after {
      display: none;
    }
  }
}

@import '~scss/variables.scss';

.deleteIcon {
  height: 50px;
  color: $color-red;
}
h3 {
  color: $color-gray-dark;
}
h4 {
  color: $color-gray;
  font-size: 1.5rem !important;
}

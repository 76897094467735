// Variables
$color-primary: #5ac692;
$color-secondary: #ececf0;

$color-green-lightest: #d2f7e5;
$color-green-light: #a1e4c4;
$color-green: #5ac692;
$color-green-dark: #5ac692;
$color-green-darkest: #1f9953;

$color-blue-lightest: #b6cbea;
$color-blue-light: #638ccb;
$color-blue: #194384;
$color-blue-dark: #2156a8;
$color-blue-darkest: #1a3384;

$color-orange: #ff7d00;
$color-green: #54d3ad;
$color-red: #eb2358;
$color-red-border: #c00e0e;
$color-white: #ffffff;
$color-cyan-light: #d8ebf1;

$color-gray-input: #3c3c3c;

$color-gray-darker: #666666;
$color-gray-dark: #3f4a5a;
$color-gray: #777d87;
$color-gray-light: #c1c1c1;
$color-gray-lighter: #e6e6e6;
$color-gray-lightest: #f7fafb;

$color-gray-banner-text: #828c9a;
$color-gray-banner-bg: #f3f6fa;

$color-muted: #ececf0;
$color-warning: #f9edcb;
$color-success: #d2f7e5;
$color-danger: #ffccd9;

$input-border-radius: 2px;

$font-body: 'Fira Sans', sans-serif;
$font-heading: 'Fira Sans', sans-serif;
$font-code: 'Source Code Pro', monospace;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$portal-header-height: 66px;
$portal-sidebar-width: 205px;

$mobile-width: 414px;

%label {
  font-weight: normal;
  font-weight: 600;
  color: $color-blue-darkest;
  font-size: 1.3rem;
  line-height: 1.6;
  display: block;
  margin-bottom: 1rem;
}

%panel {
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-light;
  padding: 3rem 3rem;
}

@import '~scss/variables.scss';

p {
  font-size: 1.25rem;
  color: $color-gray;
}

.loaderPanel {
  padding-top: 5rem;
  text-align: center;
  font-size: 2rem;
  color: $color-gray;

  div + span {
    vertical-align: top;
  }
}
.emptyPanel {
  h3 {
    margin-top: 4rem;
  }
  p {
    margin: 3rem 25rem 6rem;
  }
}
.sectionHeader {
  margin: 3rem 0;

  h4 {
    margin-bottom: 1rem;
  }

  button {
    margin-top: 3rem;
  }
}

.tile {
  &.appTile {
    display: flex !important;
    min-height: 180px !important;
  }

  .tileMetaInfoRegion {
    font-size: 1.1rem;

    .clientToken {
      font-size: 1.1rem;
      color: $color-gray;

      span,
      input {
        display: inline-block;
      }

      span {
        padding-right: 1rem;
      }
      input {
        font-size: 1.1rem;
        color: $color-gray;
        width: 104px;
      }
      input {
        padding: 0;
        box-shadow: none;
        margin-bottom: 0;
        margin-top: 0.5rem;
        font-family: $font-code !important;
      }

      .copyButton {
        margin-top: 0.25rem;
        margin-left: 1.5rem;
        float: right;

        svg {
          vertical-align: text-bottom;
          cursor: pointer;
          color: $color-blue-light;
        }
      }
    }
  }
}

.centerParagraph {
  width:16vw; 
  text-align:center;
  margin-bottom:32px;
}

.tileActionButton {
  background-color: $color-blue;
  color: $color-white;
  padding: 13px;

  &:hover:not(:disabled) {
    background: darken($color-blue, 3);
    transition: 0.1s;
  }

  svg {
    color: $color-blue-light;
  }
}

@import '~scss/variables.scss';

.tile {
  display: inline-block;
  margin: 0 34px 21px 0;

  &:nth-child(4n) {
    margin-right: 0;
    clear: both;
  }

  .tileCard {
    box-shadow: 0 2px 6px 0 rgba(33, 86, 168, 0.25) !important;
    width: 242px;
    min-height: 160px;
    display: flex;
    flex-direction: column;

    .header {
      padding-bottom: 0;
      margin-bottom: 0;

      button {
        margin-top: 8px;
        padding: 0.5rem;

        svg {
          font-size: 2.5rem;
        }
      }
    }

    .tileContent {
      flex: 1 0 auto;
      .title,
      .desc {
        color: $color-gray;
        margin: 0;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .tileActions {
      display: flex;
      flex-direction: row;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.MuiTooltip-tooltip {
  font-size: 12px !important;
}

.datepicker.MuiFormControl-root {
  font-weight: 300;
  font-size: 1.4rem;
  width: 100%;
  
  .MuiInput-root {
    box-shadow: 0 2px 6px 0 rgba(33, 86, 168, 0.25);
    padding: 1.5rem 2rem;
    background-color: $color-white;
    border-radius: $input-border-radius;
    margin-bottom: 2.5rem;
    border: 0;

    .MuiInput-input {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
      'Segoe UI Emoji', 'Segoe UI Symbol';
      color: $color-gray;
      outline: none;
      scroll-margin-top: 3.8rem;
      scroll-margin-bottom: 3rem;
      padding: 0;
      box-shadow: none;
      font-size: 1.4rem;
    }

    .MuiIconButton-root {
      padding: 0;
    }
  }

  .MuiInput-underline {
    &:before {
      border-bottom: none;
    }
    &::after {
      border-bottom: none;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
  }

  .MuiFormHelperText-root.Mui-error {
    margin-bottom: 25px;
    margin-top: -15px;
    font-size: 1.4rem;
    font-weight: 300;
    color: $color-red;
  }
}
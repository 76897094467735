@import '~scss/variables.scss';

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  background-color: $color-white;
  margin: 0 3rem 3rem;
  padding: 0;
}
.header {
  text-align: right;
}
.closeButton {
  color: $color-gray-light !important;
  padding: 0 !important;
  min-width: auto !important;

  &:hover,
  &:focus {
    color: $color-gray-dark !important;
    background-color: transparent !important;
  }

  svg {
    width: 3rem;
    height: 3rem;
  }
}

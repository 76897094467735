@import '~scss/variables.scss';

.oauthListItem {
  margin-bottom: 2rem;

  h4,
  .description {
    margin: 0 1rem 0.5rem;
  }
  a,
  .description {
    font-size: 1.25rem;
  }
  .description {
    color: $color-gray;
  }
  a {
    display: block;
    margin: 0 1rem 0.5rem;
  }
  .clientToken {
    font-size: 1.1rem;
    color: $color-gray;

    span,
    pre,
    input {
      display: inline-block;
    }

    span {
      padding-right: 1rem;
    }
    pre,
    input {
      font-size: 1.25rem;
      color: $color-gray;
      width: 200px;
    }
    input {
      padding: 0;
      box-shadow: none;
      margin-bottom: 1rem;
      margin-top: 1rem;
      font-family: $font-code !important;
    }

    .copyButton {
      display: inline-block;
      margin-left: 1.5rem;

      svg {
        vertical-align: text-bottom;
        cursor: pointer;
        color: $color-blue-light;
      }
    }
  }
}
